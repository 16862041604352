import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { Input, Field } from 'components/v2/forms';

const InputComponent = styled(Input)`
  height: 40px;
`;

const FieldComponent = styled(Field)`
  flex-basis: 235px;
`;

const Wrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Fields = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const Addresses = ({ className, control, items, title }) => {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>

      <Fields>
        {items.map(({ name, label, placeholder }) => {
          return (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field }) => (
                <FieldComponent label={label}>
                  <InputComponent {...field} disabled placeholder={placeholder} />
                </FieldComponent>
              )}
            />
          );
        })}
      </Fields>
    </Wrapper>
  );
};

Addresses.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  control: PropTypes.any,
  items: PropTypes.array
};

export default Addresses;
