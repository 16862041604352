import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import formatPrice from 'utils/formatPrice';
import moment from 'utils/moment';

import Button from 'components/forms/Button';
import { getTaxObject } from 'utils/getTaxesRules/getTaxesRules';

import Restrictions from './Restrictions';

const format = 'DD.MM.YYYY';

const DateContainer = styled.div`
  color: #4a515c;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

const DescriptionContainer = styled.div`
  color: #979ba0;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 14px;
`;

const SubmitText = styled.div`
  color: #4a515c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
`;

const SubmitPrice = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #4a515c;
`;

const SubmitContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SubmitContentAgent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 17px;
  color: #3aa6d2;

  ${SubmitText} {
    color: #3aa6d2;
  }

  ${SubmitPrice} {
    color: #3aa6d2;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }
`;

const ComissionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #3aa6d2;

  ${SubmitText} {
    color: #3aa6d2;
  }

  ${SubmitPrice} {
    color: #3aa6d2;
  }
`;

const GuestsContainer = styled.div`
  color: #4a515c;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

const InfoContainer = styled.div`
  max-width: 441px;
`;

const ComissionDescription = styled.div`
  height: 18px;
  padding: 0 5px;
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #e3f4fc;
  display: flex;
  align-items: center;
  color: #6b707b;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
`;

const ComissionPrice = styled.div`
  color: #6b707b;
  font-size: 10px;
  margin-top: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: right;
`;

const SubmitContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #3aa6d2;
  border-radius: 4px;
  background-color: #effaff;
  padding: 16px;
  min-width: 361px;
  max-width: 361px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 34px;

  ${Button} {
    width: 100%;
    margin-top: 12px;
  }

  ${Restrictions} {
    margin-top: 12px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TaxBox = styled.div`
  padding: 16px 24px;
  background: #f6f8fb;
  border: 1px solid #d1d5db;
  border-radius: 4px;
`;

const TaxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #4a515c;
`;

const TaxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  ${SubmitPrice} {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }
`;

function Bottom({ className, formData, isPending, showComission, disabledAction, tariff, hotel = {}, restrictionsData }) {
  const { t } = useTranslation();

  if (!formData) return <div></div>;

  const taxObject = hotel?.taxes && getTaxObject(hotel);
  const { gross_price, net_price, duration, check_out, check_in, slots, discount_in_percent, discount } = formData;

  const checkIn = moment(check_in);
  const checkOut = moment(check_out);
  const checkOutText = checkOut._isValid ? checkOut.format(format) : t('Common.Dates.Invalid');

  const durationText = checkOut._isValid ? `(${t('Orders.Reservations.Duration', duration)})` : '';

  const guests = t('UI.Guests', { count: slots.length });

  return (
    <Wrapper className={className}>
      <InfoContainer>
        <DateContainer>
          {checkIn.format(format)} - {checkOutText} {durationText}
        </DateContainer>

        <GuestsContainer>{guests}</GuestsContainer>

        {restrictionsData?.restrictions?.length > 0 && <Restrictions restrictionsData={restrictionsData} />}

        <DescriptionContainer>{t('Hotel.RoomType.Form.Description')}</DescriptionContainer>
      </InfoContainer>
      <BottomContainer>
        <SubmitContainer>
          {showComission && (
            <SubmitContentAgent>
              <SubmitText>{t('Orders.Hotel.Badge.NetPrice.Agent')}</SubmitText>

              <SubmitPrice>{formatPrice(net_price)}</SubmitPrice>
            </SubmitContentAgent>
          )}

          <SubmitContent>
            <SubmitText>{t('Orders.Hotel.Badge.NetPrice.Tourist')}</SubmitText>

            <SubmitPrice>{formatPrice(gross_price)}</SubmitPrice>
          </SubmitContent>

          {showComission && (
            <ComissionContent>
              <ComissionDescription>{t('Common.Commission', { value: discount_in_percent })}</ComissionDescription>

              <ComissionPrice>{t('UI.Price', { price: discount })}</ComissionPrice>
            </ComissionContent>
          )}

          <Button type='submit' loading={isPending} disabled={disabledAction || restrictionsData.hasActiveRestriction}>
            {t('Orders.Hotel.Submit')}
          </Button>
        </SubmitContainer>
        {hotel?.taxes?.length > 0 && (
          <TaxBox>
            <TaxTitle>{name}</TaxTitle>
            <TaxContent>
              <SubmitText>{`${taxObject?.paymentDescription} 333${taxObject?.conditionDescription}`}</SubmitText>
              <SubmitPrice>{taxObject?.price}</SubmitPrice>
            </TaxContent>
          </TaxBox>
        )}
      </BottomContainer>
    </Wrapper>
  );
}

Bottom.propTypes = {
  showComission: PropTypes.bool,
  isPending: PropTypes.bool,
  disabledAction: PropTypes.bool,
  className: PropTypes.string,
  formData: PropTypes.object,
  tariff: PropTypes.object,
  hotel: PropTypes.object,
  restrictionsData: PropTypes.object
};

export default styled(Bottom)``;
