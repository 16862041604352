import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Header from './Header';
import Slots from './Slots';

import Modal from 'components/ui/Modal';
import Description from 'components/ui/RoomTypeDescription';

const MoreButton = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
  margin-top: 12px;
`;

const DescriptionComponent = styled(Description)`
  width: 700px !important;
  min-height: 553px;
  padding: 10px 14px;
  box-sizing: border-box;
  box-shadow: none;
`;

const DescriptionContainer = styled.div`
  height: 32px;
  width: 100%;
  border-radius: 4px;
  background-color: #F6F8FB;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #979BA0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 20px;
`;

const GuestsDescription = styled.div`

`;

const GuestsHint = styled.div`

`;

const Wrapper = styled.div`
  padding: 19px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  box-sizing: border-box;

  ${DescriptionContainer} + ${Slots} {
    margin-top: 14px;
  }
`;

@observer
class Reservation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    reservation: PropTypes.object.isRequired,
    showCommission: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: '',
    showCommission: false
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  handleOpen = () => {
    this.setState({ opened: true });
  }

  handleClose = () => {
    this.setState({ opened: false });
  }

  render() {
    const { reservation, reservation: { seats }, t, showCommission, ...rest } = this.props;
    const seatsArr = [t('Orders.TableItem.Seats.Main', seats.main)];

    if (seats.extra.count > 0) {
      seatsArr.push(t('Orders.TableItem.Seats.Extra.FullText', seats.extra));
    }

    const seatsText = seatsArr.join(', ');
    const placeText = t('Common.Place', { count: seats.extra.count || seats.main.count });

    return (
      <Wrapper {...rest}>
        <Header
          reservation={reservation}
          showCommission={showCommission}
        />

        <MoreButton onClick={this.handleOpen}>
          {t('Order.RoomType.Action.More')}
        </MoreButton>

        <DescriptionContainer>
          <GuestsDescription>
            {seatsText} {placeText}
          </GuestsDescription>

          <GuestsHint>
            {t('Orders.TableItem.Description')}
          </GuestsHint>
        </DescriptionContainer>

        <Slots
          reservation={reservation}
          showCommission={showCommission}
        />

        <Modal
          opened={this.state.opened}
          onClose={this.handleClose}
        >
          <DescriptionComponent
            {...reservation}
            variant='modal'
            onClose={this.handleClose}
            opened={this.state.opened}
          />
        </Modal>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservation))``;
