import * as Yup from 'yup';

import { customizePhoneValue } from 'pages/Auth/containers/Register/utils/customizePhoneNumber';

export const schema = Yup.object().shape({
  name: Yup.string().optional(),
  type: Yup.object().shape({ tag: Yup.string().optional() }).optional(),
  inn: Yup.string()
    .matches(/^\d{10}$|^\d{12}$/, 'ИНН должен содержать 10 или 12 цифр')
    .optional(),
  kpp: Yup.string().when('type', {
    is: (type) => type.tag === 'LEGAL',
    then: Yup.string()
      .matches(/^\d{9}$/, 'КПП должен содержать 9 цифр')
      .optional(),
    otherwise: Yup.string().nullable()
  }),
  short_name: Yup.string().optional(),
  ogrn: Yup.string()
    .matches(/^\d{13}$|^\d{15}$/, 'ОГРН должен содержать 13 или 15 цифр')
    .optional(),
  tax_type: Yup.object().shape({ tag: Yup.string().optional() }).optional(),
  agency_type: Yup.object().shape({ tag: Yup.string().optional() }).optional(),
  email: Yup.string().email('Некорректный email').optional(),
  phone: Yup.string()
    .test('phone', 'Некорректный номер телефона', (value) => {
      if (!value) return true;

      value = customizePhoneValue(value);

      if (value.startsWith('+7')) {
        return /^\+?\d{11}$/.test(value);
      } else {
        return /^\+?\d{10}$/.test(value);
      }
    })
    .optional(),
  director: Yup.object().shape({
    full_name: Yup.string().optional(),
    post: Yup.string().optional()
  }),
  accountant: Yup.object().shape({
    full_name: Yup.string().optional(),
    post: Yup.string().optional(),
    email: Yup.string().email('Некорректный email').optional(),
    phone: Yup.string()
      .test('phone', 'Некорректный номер телефона', (value) => {
        if (!value) return true;

        value = customizePhoneValue(value);

        if (value.startsWith('+7')) {
          return /^\+?\d{11}$/.test(value);
        } else {
          return /^\+?\d{10}$/.test(value);
        }
      })
      .optional()
  }),
  legal_address: Yup.object().shape({
    postal_code: Yup.string()
      .matches(/^\d{6}$/, 'Индекс должен содержать 6 цифр')
      .optional(),
    country: Yup.string().optional(),
    city: Yup.string().optional(),
    street: Yup.string().optional(),
    building_number: Yup.string().optional(),
    office_number: Yup.string().optional()
  }),
  actual_address: Yup.object().shape({
    postal_code: Yup.string()
      .matches(/^\d{6}$/, 'Индекс должен содержать 6 цифр')
      .optional(),
    country: Yup.string().optional(),
    city: Yup.string().optional(),
    street: Yup.string().optional(),
    building_number: Yup.string().optional(),
    office_number: Yup.string().optional()
  }),
  bank_details: Yup.object().shape({
    name: Yup.string().optional(),
    kpp: Yup.string()
      .matches(/^\d{9}$/, 'КПП должен содержать 9 цифр')
      .optional()
      .nullable(), // ??
    bik: Yup.string()
      .matches(/^\d{9}$/, 'БИК должен содержать 9 цифр')
      .optional(),
    kor_schet: Yup.string()
      .matches(/^\d{20}$/, 'Корреспондетский счет должен содержать 20 цифр')
      .optional(),
    ras_schet: Yup.string()
      .matches(/^\d{20}$/, 'Расчетный счет должен содержать 20 цифр')
      .optional()
  })
});
