import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const Wrapper = styled.form`
`;

function Form({
  children,
  mode,
  onSubmit,
  onError,
  onReset,
  defaultValues,
  schema,
  className
}) {
  const methods = useForm({
    mode,
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const handleReset = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onReset();
  };

  return (
    <FormProvider {...methods}>
      <Wrapper
        onSubmit={methods.handleSubmit(onSubmit, onError)}
        onReset={handleReset}
        className={className}
      >
        {children}
      </Wrapper>
    </FormProvider>
  );
}

Form.defaultProps = {
  mode: 'all',
  onSubmit: () => {},
  onError: () => {},
  onReset: () => {}
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  mode: PropTypes.oneOf(['onChange', 'onBlur', 'onSubmit', 'onTouched', 'all']),
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func,
  defaultValues: PropTypes.object,
  schema: PropTypes.any
};

export default styled(Form)``;
