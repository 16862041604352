import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  color: #4a515c;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;

const Label = styled.label`
  display: flex;
  width: inherit;

  ${({ isRequired }) =>
    isRequired &&
    css`
      ${Text}:after {
        content: '*';
        margin-left: 4px;
      }
    `}

  ${({ isError }) =>
    isError &&
    css`
      ${Text} {
        color: #ff5656;
      }
    `}
`;

const Element = styled.div`
  width: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  ${({ isInline }) =>
    isInline &&
    css`
      ${Label} {
        flex-direction: row;
        align-items: center;

        ${Text} + ${Element} {
          margin-left: 16px;
        }
      }
    `}

  ${({ isInline }) =>
    !isInline &&
    css`
      ${Label} {
        flex-direction: column;
        align-items: baseline;

        ${Text} + ${Element} {
          margin-top: 8px;
        }
      }
    `}
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff5656;
  margin-top: 4px;
`;

function Field({ name, label, required, error, disabled, inline, size, children, className }) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { name, error, size });
    }

    return child;
  });

  return (
    <Wrapper size={size} isInline={inline} className={className}>
      <Label isError={error} isRequired={required}>
        {label && <Text>{label}</Text>}

        <Element>{childrenWithProps}</Element>
      </Label>

      {!!error && <ErrorMessage>{error?.message}</ErrorMessage>}
    </Wrapper>
  );
}

Field.defaultProps = {
  required: false,
  error: false,
  disabled: false,
  inline: false,
  size: 'medium'
};

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large'])
};

export default styled(Field)``;
