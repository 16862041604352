import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Input, Field } from 'components/v2/forms';

import { Controller, useFormContext } from 'react-hook-form';

const Wrapper = styled(Field)``;

function EmailField({ name, required, className }) {
  const { t } = useTranslation();

  const { formState: { errors } } = useFormContext();

  return (
    <Wrapper
      required={required}
      error={!!errors?.[name]}
      name={name}
    >
      <Controller
        render={({ field }) => (
          <Input
            {...field}
            error={!!errors?.[name]}
            placeholder={t('Profile.Email.Label')}
          />
        )}
      />
    </Wrapper>
  );
}

EmailField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequire,
  required: PropTypes.bool
};

export default styled(EmailField)``;
