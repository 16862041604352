import { types } from 'mobx-state-tree';
import { Contract, PaymentMethod, Commission, Image, Address } from 'stores/models';

const mockService = {
  options: []
};

const mockManipulation = {
  options: []
};

const Hotel = types.model('Hotel', {
  id: types.string,
  name: types.string,
  address: Address
});

const RoomType = types.model('RoomType', {
  id: types.string,
  name: types.string,
  beds: types.number,
  extra_beds: types.number,
  images: types.array(Image),
  rm_count: types.maybeNull(types.number),
  area: types.maybeNull(types.number)
});

const ManipulationOption = types.model('AvailablePeriod', {
  name: types.string
});

const Manipulation = types.model('AvailablePeriod', {
  options: types.optional(types.array(ManipulationOption), []),
  quantity: types.optional(types.array(types.number), []),
  disclaimer: types.maybeNull(types.string)
});

const ServiceObject = types.model('Service', {
  options: types.optional(types.array(ManipulationOption), []),
  disclaimer: types.maybeNull(types.string)
});

const Rate = types.model('Rate', {
  type: types.string,
  description: types.string,
  count: types.number,
  age_group: types.string,
  occupation: types.string
});

const Tariff = types
  .model('Tariff', {
    id: types.identifier,
    billing_hour: types.string,
    manipulation: types.optional(Manipulation, mockManipulation, [null, undefined]),
    service: types.optional(ServiceObject, mockService, [null, undefined]),
    description: types.string,
    name: types.string,
    meal_disclaimer: types.maybeNull(types.string),
    rates: types.array(Rate)
  });

const Document = types.model('Document', {
  number: types.string
});

const Traveller = types.model('Traveller', {
  id: types.identifier,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string),
  full_name: types.maybeNull(types.string),
  age: types.number,
  date_of_birth: types.maybeNull(types.string),
  age_group: types.enumeration(['adult', 'child']),
  gender: types.enumeration(['male', 'female', 'not_defined']),
  document: types.maybeNull(Document)
});

const DailyPrice = types.model('DailyPrice', {
  day: types.string,
  price: types.number,
  payment_method: PaymentMethod
});

const Slot = types.model('Slot', {
  id: types.identifier,
  count: types.number,
  price: types.number,
  gross_price: types.number,
  net_price: types.number,
  cash: types.boolean,
  rate: Rate,
  prices: types.optional(types.array(DailyPrice), []),
  traveller: types.maybeNull(Traveller),
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  contract: types.maybeNull(Contract)
});

const Reservation = types.model('Reservation', {
  id: types.identifier,
  price: types.number,
  gross_price: types.number,
  net_price: types.number,
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  check_in: types.string,
  check_out: types.string,
  hotel: Hotel,
  room_type: RoomType,
  tariff: Tariff,
  contract: types.maybeNull(Contract),
  commission: Commission,
  slots: types.array(Slot)
});

export { Reservation };
