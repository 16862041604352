import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Select, Field } from 'components/v2/forms';

import { Controller, useFormContext } from 'react-hook-form';

const Wrapper = styled(Field)``;

function RoleField({ name, required, className }) {
  const { t } = useTranslation();

  const { formState: { errors } } = useFormContext();

  const options = [
    { key: 'admin', value: t('Profile.Role.Value', { value: 'admin' }) },
    { key: 'manager', value: t('Profile.Role.Value', { value: 'manager' }) }
  ];

  return (
    <Wrapper
      required={required}
      error={!!errors?.[name]}
      name={name}
    >
      <Controller
        render={({ field }) => (
          <Select
             {...field}
            placeholder={t('Profile.Role.Label')}
            simpleValue
            options={options}
          />
        )}
      />
    </Wrapper>
  );
}

RoleField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequire,
  required: PropTypes.bool
};

export default styled(RoleField)``;
