import styled, { css } from 'styled-components';

const rcSelect = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  border-radius: 3px;
  background: #ffffff;
  color: #4a515c;
  cursor: pointer;
  border: 1px solid #d1d5db;
  box-sizing: border-box;

  &.rc-select-open {
    .rc-select-arrow {
      margin-top: -3px;
      transform: rotate(-180deg);
    }
  }

  .rc-select-selector + .rc-select-arrow {
    margin-left: 12px;
  }

  ${({ isSearch }) =>
    isSearch &&
    css`
      .rc-select-selection-item {
        display: none;
      }
    `}

  ${({ isSearch }) =>
    !isSearch &&
    css`
      .rc-select-selection-search {
        display: none;
      }
    `}
`;

const rcSelector = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: inherit;

  .rc-select-selection-search {
  }

  .rc-select-selection-item {
  }

  .rc-select-selection-placeholder {
    color: #999999;
  }
`;

const rcArrow = css`
  flex-shrink: 0;
  font-size: 5px;
`;

const rcSelectDropdown = css`
  z-index: 1;
  border-radius: 4px;

  &.rc-select-dropdown-hidden {
    display: none;
  }
`;

const rcVirtualList = css`
  border: 1px solid #abb3bf;
  box-sizing: border-box;
  border-radius: 4px;

  .rc-select-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 32px;
    padding: 0 12px;
    background: #ffffff;
    cursor: pointer;

    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }

    &:first-child {
      border-radius: 4px 4px 0px 0px;
    }

    &.rc-select-item-option-active {
      background-color: #c5d2e2;
    }

    &.rc-select-item-option-selected {
      background-color: #c5d2e2;
    }

    .rc-select-item-option-state {
      display: none;
    }
  }
`;

const rcSelectItemEmpty = css`
  border: 1px solid #c8c4bc;
  border-top: none;
  padding: 8px 12px;
  background: #ffffff;
  cursor: not-allowed;
`;

const rcSelectMultiple = css`
  height: auto;

  .rc-select-selector {
    position: static;
    padding: 8px 0px;
  }
`;

const rcSelectSelectionOverflow = css`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4px;

  .rc-select-selection-overflow-item-suffix {
    display: none;
  }
`;

const rcSelectSelectionOverflowItem = css`
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 2px 6px;
  background-color: #d2d1cc;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  font-family: Helvetica Neue;
  font-size: 13px;

  .rc-select {
    ${rcSelect}

    .rc-select-selector {
      ${rcSelector}
    }

    .rc-select-arrow {
      ${rcArrow}
    }
  }

  .rc-select-multiple {
    ${rcSelectMultiple}

    .rc-select-selection-overflow {
      ${rcSelectSelectionOverflow}
    }

    .rc-select-selection-overflow-item {
      ${rcSelectSelectionOverflowItem}
    }
  }

  .rc-select-dropdown {
    ${rcSelectDropdown}

    .rc-virtual-list {
      ${rcVirtualList}

      .rc-virtual-list-holder {
        background: #fff;

        .rc-virtual-list-holder-inner {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }

  .rc-select-item-empty {
    ${rcSelectItemEmpty}
  }
`;

export default Wrapper;
