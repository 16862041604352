import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from './assets/ArrowIcon.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: -12px;
`;

const ArrowLeft = styled(ArrowIcon)`
  cursor: pointer;
`;

const ArrowRight = styled(ArrowIcon)`
  cursor: pointer;
  transform: scale(-1);
`;

@observer
class NavBar extends Component {
  static propTypes = {
    className: PropTypes.string,
    onNextClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    handlePreviousClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired
  }

  render() {
    const {
      handlePreviousClick,
      handleNextClick,
      onNextClick,
      onPreviousClick,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <ArrowLeft
          onClick={() => handlePreviousClick()}
        />

        <ArrowRight
          onClick={() => handleNextClick()}
        />
      </Wrapper>
    );
  }
}

export default NavBar;
