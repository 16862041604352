import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useOrganizationTypesOptions = () => {
  const [organizationTypesOptions, setOrganizationTypesOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { data }
        } = await instance.get('/api/filters/organization_types');

        setOrganizationTypesOptions(data);
      } catch (e) {
        console.log('Organization Types options error', e);
      }
    };

    fetch();
  }, []);

  return organizationTypesOptions;
};

export default useOrganizationTypesOptions;
