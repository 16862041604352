import { t } from 'utils/localization';

export const contactsItems = [
  {
    name: 'legal_entity.email',
    label: t('Auth.Register.LegalEntity.Email.Label'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'legal_entity.email2',
    label: t('Auth.Register.LegalEntity.Email.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'legal_entity.phone',
    label: t('Auth.Register.Manager.Phone.Label'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];

export const accoutingItems = [
  {
    name: 'legal_entity.accountant.full_name',
    label: t('Auth.Register.LegalEntity.Accounting.Label'),
    placeholder: t('Auth.Register.Manager.FIO.Placeholder')
  },
  {
    name: 'legal_entity.accountant.email',
    label: t('Auth.Register.LegalEntity.Accounting.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder2')
  },
  {
    name: 'legal_entity.accountant.phone',
    label: t('Auth.Register.LegalEntity.Accounting.Label3'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];

export const actualAddressItems = [
  {
    name: 'legal_entity.actual_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6')
  },
  {
    name: 'legal_entity.actual_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label')
  },
  {
    name: 'legal_entity.actual_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2')
  },
  {
    name: 'legal_entity.actual_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.actual_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.actual_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder')
  }
];

export const legalAddressItems = [
  {
    name: 'legal_entity.legal_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6')
  },
  {
    name: 'legal_entity.legal_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label')
  },
  {
    name: 'legal_entity.legal_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2')
  },
  {
    name: 'legal_entity.legal_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.legal_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.legal_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder')
  }
];

export const defaultValues = {
  email: '', // Email менеджера, обязательное
  phone: '', // Телефон менеждера, обязательное
  first_name: '', // Имя менеджера, обязательное
  last_name: '', // Фамилия менеджера, обязательное
  middle_name: '', // Отчетсво менеджера, необязательное
  post: '', // Должность менеджера, необязательное
  equalAddress: false, // флаг для простановки равности адресов
  legal_entity: {
    // Юридическая информация
    name: '', // Полное название, обязательное
    type: { tag: 'default', name: 'Выберите' }, // Тип организации, обязательное
    inn: '', // ИНН, обязательное
    kpp: '', // КПП, для type == LEGAL обязательное
    short_name: '', // Короткое название, обязательное
    ogrn: '', // ОГРН, обязательное
    tax_type: { tag: 'default', name: 'Выберите' }, // Налогообложение, обязательное
    agency_type: { tag: 'default', name: 'Выберите' }, // Сеть агенств, необязательное
    email: '', // Контакты Email основной, обязательное
    phone: '', // Контакты Телефон, обязательный
    director: {
      // Руководитель необязательные
      full_name: '', // О компании ФИО руководителя
      post: '' // О компании Должность
    },
    accountant: {
      // Данные бухгалтерии
      full_name: '', // Данные бухгалтерии ФИО бухгалтера
      post: 'Бухгалтер', // Данные Бухгалтерии тип Бухгалтер
      email: '', // Данные Бухгалтерии Email
      phone: '' // Данные Бухгалтерии  Телефон
    },
    legal_address: {
      // Юридический адрес, обязательный
      postal_code: '',
      country: '',
      city: '',
      street: '',
      building_number: '',
      office_number: ''
    },
    actual_address: {
      // Фактический адрес, обязательный
      postal_code: '',
      country: '',
      city: '',
      street: '',
      building_number: '',
      office_number: ''
    },
    bank_details: {
      // Банковские реквизиты, обязательный
      name: '', // Название банка
      kpp: '', // КПП Банка
      bik: '', // БИК банка
      kor_schet: '', // Кор счет
      ras_schet: '' // Рассчетный счет
    }
  }
};
