import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import _compact from 'lodash/compact';

import Photos from './Photos';
import Feed from './Feed';
import Manipulation from './Manipulation';
import Cancellation from './Cancellation';
import Tabs from './Tabs';

import { typography } from 'theme/mixins';
import { observer } from 'mobx-react';

const Title = styled.div`
  color: #4a515c;
  padding-bottom: 8px;
  ${typography(16, 19, 700)};
`;

const SubTitle = styled.div`
  ${typography(14, 19, 500)};
`;

const InformationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${Photos} {
    flex-shrink: 0;
  }
`;

const Information = styled.div`
  color: #4c4c4c;
  padding-top: 7px;
  ${typography(13, 19)};
`;

const Special = styled.div`
  padding-top: 12px;
`;

const Label = styled.div`
  color: #4c4c4c;
  width: 330px;
  ${typography(13, 16)};
`;

const Features = styled.div`
  margin-top: 16px;
`;

const Wrapper = styled.div`
  width: inherit;
  height: auto;
  padding: 26px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  ${Feed}, ${Manipulation}, ${Cancellation} {
    margin-top: 20px;
  }
`;

@observer
class Description extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object,
    room_type: PropTypes.object,
    tariff: PropTypes.object,
    t: PropTypes.func,
    seats: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  get services() {
    const { tariff: { manipulation, service } } = this.props;

    return [
      ...manipulation.options,
      ...service.options
    ];
  }

  get features() {
    const { t, tariff: { rates, service, manipulation, meal_disclaimer } } = this.props;

    const ratesArray = rates.filter(rate => rate.occupation === 'main' && rate.count === 0).length > 0 ? [t('Tariff.Features.Sharing')] : [];
    const disclaimers = _compact([service?.disclaimer, manipulation?.disclaimer, meal_disclaimer]);

    return [
      ...ratesArray,
      ...disclaimers
    ];
  }

  render() {
    const { hotel, room_type, seats, tariff, t, ...rest } = this.props;
    const { meal_option } = tariff;

    return (
      <Wrapper {...rest}>
        <Title>
          {t('Order.RoomDescription.Title')}
        </Title>

        <InformationWrapper>
          <Information>
            <Tabs roomType={room_type} />
          </Information>

          {room_type.images.length > 0 && (
            <Photos images={room_type.images} />
          )}
        </InformationWrapper>

        {this.services.length > 0 && (
          <Manipulation services={this.services} />
        )}

        {meal_option && (
          <Feed tariff={tariff} />
        )}

        <Cancellation tariff={tariff} />

        {this.features.length > 0 && (
          <Features>
            <SubTitle>
              {t('Tariff.Features.Title')}
            </SubTitle>

            {this.features.map((label, idx) => (
              <Special key={idx}>
                <Label>
                  {label}
                </Label>
              </Special>
            ))}
          </Features>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Description))``;
