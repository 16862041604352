import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Heading from '../../components/Heading';

const Title = styled.div`
  color: #9cb4ca;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.div`
  text-wrap: balance;
  color: #4f5660;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Content = styled.div`
  align-self: center;
  padding: 70px 128px 81px;
  width: 640px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;

  ${Title} + ${Description} {
    margin-top: 20px;
  }
`;

const Logo = styled(Heading)`
  margin: 0 0 90px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
  justify-content: center;
  align-content: center;
`;

function SuccessRegister({ className }) {
  return (
    <Wrapper className={className}>
      <Logo />
      <Content>
        <Title>Проверьте свою почту</Title>

        <Description>На вашу почту была отправлена ссылка для завершения регистрации.</Description>
      </Content>
    </Wrapper>
  );
}

SuccessRegister.defaultProps = {
  onSubmit: () => {}
};

SuccessRegister.propTypes = {
  className: PropTypes.string
};

export default styled(SuccessRegister)``;
