import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CompanyForm from './CompanyForm';

import { schema } from './schema';
import useCompany from './hooks/useCompany';
import ErrorModal from 'pages/Auth/containers/Register/ErrorModal';

const Wrapper = styled.div``;

function Company({ className }) {
  const [{ data, loading, error }, { updateInfo, setError }] = useCompany();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (!data) return;

    reset(data?.legal_entity);
  }, [data]);

  const handleCloseModal = () => {
    setError(null);
  };

  return (
    <Wrapper className={className}>
      <CompanyForm {...{ control, errors, handleSubmit, loading, updateInfo, reset }} />

      {error && <ErrorModal opened={!!error} onClose={handleCloseModal} />}
    </Wrapper>
  );
}

Company.propTypes = {
  className: PropTypes.string
};

export default styled(Company)``;
