import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import List from '../List';

const Wrapper = styled.div``;

function Cancellation({ className, tariff, ...rest }) {
  const { t } = useTranslation();

  const { cancellation_policy, billing_hour } = tariff;

  const cancellationPolicy = useMemo(() => {
    if (!cancellation_policy) return [t('Tariffs.Cancellation.FullRefund')];

    if (cancellation_policy.has_full_refund) {
      return [t('Tariffs.Cancellation.FullRefund')];
    }

    if (cancellation_policy.has_non_refund) {
      return [t('Tariffs.Cancellation.NonRefund')];
    }

    return cancellation_policy.policy_rules.map(rule => {
      const penalty = t('Tariffs.CancellationPolicy.Penalty', { ...rule.penalty, billing_hour });
      const deadline = t('Tariffs.CancellationPolicy.Deadline', { ...rule.deadline, penalty });

      return deadline;
    });
  }, [tariff]);

  return (
    <Wrapper className={className}>
      <List
        title={t('Tariff.Cancellation.Title')}
        items={cancellationPolicy}
        {...rest}
      />
    </Wrapper>
  );
}

Cancellation.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object
};

export default styled(Cancellation)``;
