import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import { BackLink } from 'components/ui';
import Heading from '../../components/Heading';
import { schema } from './schema';
import { defaultValues } from './formFieldsData';

import LegalEntityForm from './LegalEntityForm';
import ManagerForm from './ManagerForm';
import useCompanyInfo from './hooks/useCompanyInfo';
import ErrorModal from './ErrorModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
`;

const Back = styled(BackLink)`
  border-radius: 56px;
  padding: 8px 16px;
  flex: 0;
  background: #fff;
`;

const Title = styled(Heading)`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  width: 1256px;
  margin: 55px auto 0;
`;

const Content = styled.form`
  display: flex;
  gap: 27px;
  max-width: 1256px;
  margin: 40px auto;
`;

const Register = ({ className }) => {
  const history = useHistory();
  const [{ registerError }, { register, setRegisterError }] = useCompanyInfo();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    getValues,
    setValue,
    watch
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const equalAddress = watch('equalAddress');

  useEffect(() => {
    if (isSubmitSuccessful && !registerError) {
      history.replace('/success_register');
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (!equalAddress) return;

    setValue('legal_entity.legal_address', getValues()?.legal_entity?.actual_address);
  }, [equalAddress]);

  const handleCloseModal = () => {
    setRegisterError(false);
  };

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      legal_entity: {
        ...data?.legal_entity,
        agency_type:
          data?.legal_entity?.agency_type?.tag === 'default' ? { tag: ' ', name: ' ' } : data?.legal_entity?.agency_type
      }
    };

    await register(newData);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Back to='/login' />

        <Title />
      </Header>

      <Content onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <LegalEntityForm {...{ control, errors, getValues, setValue }} />
        <ManagerForm {...{ control, errors }} />
      </Content>

      <ErrorModal opened={registerError} onClose={handleCloseModal} />
    </Wrapper>
  );
};

Register.propTypes = {
  className: PropTypes.string
};

export default Register;
