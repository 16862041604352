import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Requisites from './Requisites';
import ContactsInfo from './ContactsInfo';
import AboutCompany from './AboutCompany';
import Addresses from './Addresses';

import { accoutingItems, actualAddressItems, legalAddressItems } from '../formFieldsData';
import { Button } from 'components/forms';

const Buttons = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 24px;
  bottom: 25px;
  right: 10px;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #979ba0;
  line-height: 22px;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  border-radius: 4px;
  background: #f2f4f6;
  padding: 16px;
`;

const Separator = styled.div`
  border-radius: 4px;
  height: 2px;
  background: #eeeef3;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 40px;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
  background: #fff;
  border-radius: 4px;
`;

const CompanyForm = ({ className, control, errors, handleSubmit, loading, updateInfo, reset }) => {
  const onSubmit = async (values) => {
    await updateInfo({
      legal_entity: {
        accountant: { ...values?.accountant, post: 'Бухгалтер' },
        bank_details: values?.bank_details
      }
    });
  };

  return (
    <Wrapper className={className}>
      <AboutCompany {...{ control, errors }} />

      <AddressWrapper>
        <Addresses title='Фактический адрес' control={control} errors={errors} items={actualAddressItems} />

        <Separator />

        <Addresses title='Юридический адрес' control={control} errors={errors} items={legalAddressItems} />

        <Text>Адрес недоступен для редактирования</Text>
      </AddressWrapper>

      <ContactsInfo title='Данные бухгалтерии' control={control} errors={errors} items={accoutingItems} />

      <Separator />

      <Requisites {...{ control, errors }} />

      <Buttons>
        <Button
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          type='submit'
          size='small'
          rounded>
          Сохранить
        </Button>

        <Button onClick={() => reset()} type='reset' variant='secondary' size='small' compact>
          Отмена
        </Button>
      </Buttons>
    </Wrapper>
  );
};

CompanyForm.propTypes = {
  className: PropTypes.string,
  control: PropTypes.any,
  errors: PropTypes.any,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  updateInfo: PropTypes.func,
  reset: PropTypes.func
};

export default CompanyForm;
