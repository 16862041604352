import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { Input, Field } from 'components/v2/forms';
import Button from 'components/forms/Button';
import { t } from 'utils/localization';
import { customizePhoneValue } from '../utils/customizePhoneNumber';
import useCompanyInfo from '../hooks/useCompanyInfo';

import Hint from './Hint';

const RegisterBtn = styled(Button)`
  height: 52px;
  width: 100%;
  margin-top: 36px;
`;

const InputComponent = styled(Input)`
  height: 40px;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const Content = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.div`
  padding: 40px;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;
`;

const Wrapper = styled.div`
  width: 400px;
`;

const items = [
  {
    name: 'last_name',
    label: t('Profile.LastName.Label'),
    placeholder: t('Profile.LastName.Label')
  },
  {
    name: 'first_name',
    label: t('Profile.FirstName.Label'),
    placeholder: t('Profile.FirstName.Label')
  },
  {
    name: 'middle_name',
    label: t('Profile.MiddleName.Label'),
    placeholder: t('Profile.MiddleName.Label')
  },
  {
    name: 'post',
    label: t('Auth.Register.Manager.Position.Label'),
    placeholder: t('Auth.Register.Manager.Position.Placeholder')
  },
  {
    name: 'email',
    label: t('Auth.FormFields.Email.Label'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'phone',
    label: t('Auth.Register.Manager.Phone.Label'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];

const ManagerForm = ({ className, control, errors }) => {
  const [{ registerLoading }] = useCompanyInfo();

  return (
    <Wrapper>
      <Form className={className}>
        <Title>Данные менеджера</Title>
        <Content>
          {items.map(({ name, placeholder, label }) => (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { value, ...other } }) => (
                <Field error={errors?.[name]} label={label}>
                  <InputComponent
                    {...other}
                    value={name === 'phone' ? customizePhoneValue(value) : value}
                    placeholder={placeholder}
                  />
                </Field>
              )}
            />
          ))}
        </Content>
      </Form>

      <RegisterBtn disabled={registerLoading} loading={registerLoading} size='large'>
        Зарегистрироваться
      </RegisterBtn>

      <Hint />
    </Wrapper>
  );
};

ManagerForm.propTypes = {
  className: PropTypes.string,
  control: PropTypes.any,
  errors: PropTypes.any
};

export default ManagerForm;
