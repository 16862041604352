import * as yup from 'yup';

export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const MIDDLE_NAME = 'middle_name';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const ROLE = 'role';

const validationSchema = yup.object({
  [FIRST_NAME]: yup.string().min(2).trim().required('Required'),
  [LAST_NAME]: yup.string().min(2).trim().required('Required'),
  [MIDDLE_NAME]: yup.string().min(2).trim(),
  [EMAIL]: yup.string().email().required('Required'),
  [PHONE]: yup.string().required('Required'),
  [ROLE]: yup.string().oneOf(['admin', 'manager']).required('Required')
});

export const defaultValues = {
  [ROLE]: 'manager'
};

export default validationSchema;
